import { SORT_TYPES } from "app/constants";
import orderBy from "lodash/orderBy";

const sortTypesConfigs = {
	[SORT_TYPES.RECOMMENDED]: {
		sortFunc: items => orderBy(items, "ranking", "desc"),
	},
	[SORT_TYPES.TRIPADVISOR]: {
		sortFunc: items => orderBy(items, "tripadvisor.rating", "desc"),
	},
	[SORT_TYPES.HOLIDAYCHECK]: {
		sortFunc: items => orderBy(items, "holidaycheck.recommendation", "desc"),
	},
	[SORT_TYPES.PRICE_ASC]: {
		sortFunc: items => orderBy(items, "total", "asc"),
	},
	[SORT_TYPES.PRICE_DESC]: {
		sortFunc: items => orderBy(items, "total", "desc"),
	},
};

const updateSort = ({ products, activeSort }) => {
	const sortFunc = sortTypesConfigs[activeSort].sortFunc;
	return sortFunc(products);
};

export { updateSort };
