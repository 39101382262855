import { memo } from "react";
import PropTypes from "prop-types";
import { RATING_TYPE } from "app/constants";
import TripAdvisor from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisor";
import HolidayCheck from "app/pages/FicheProduit/components/HolidayCheck/HolidayCheck";
import { isEmpty } from "lodash";

const ProductRating = ({ tripAdvisor = {}, holidayCheck = {}, ratingType }) => {
	return (
		<div className="product-rating" data-testid="product-rating">
			{ratingType === RATING_TYPE.TRIP_ADVISOR &&
				!isEmpty(tripAdvisor) &&
				tripAdvisor.rating > 0 && (
					<TripAdvisor
						rating={tripAdvisor.rating}
						reviewsCount={tripAdvisor.reviewsCount}
						awardLabel={tripAdvisor.awardLabel}
					/>
				)}

			{ratingType === RATING_TYPE.HOLIDAY_CHECK && !isEmpty(holidayCheck) && (
				<HolidayCheck
					averageRating={holidayCheck.averageRating}
					countReviews={holidayCheck.countReviews}
					recommendation={holidayCheck.recommendation}
					size={holidayCheck.size}
					{...holidayCheck.isCompact && { isCompact: holidayCheck.isCompact }}
				/>
			)}
		</div>
	);
};

ProductRating.propTypes = {
	tripAdvisor: PropTypes.object,
	holidayCheck: PropTypes.object,
	ratingType: PropTypes.string,
};

export default memo(ProductRating);
